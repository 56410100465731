import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import BouncingMouse from "../components/bouncingmouse";
import ParallaxTitle from "../components/parallaxtitle";
import MultiBackground from "../components/multibackground";

import LegalSection from "../components/legal_section";

function LegalPage({ data }) {
  const legalData = data.legal.edges[0].node.childMarkdownRemark.frontmatter;
  const impressumData = data.impressum.edges[0].node.childMarkdownRemark;
  const datenschutzData = data.datenschutz.edges[0].node.childMarkdownRemark;

  return (
    <Layout>
      <SEO title="Impressum und Datenschutz" />

      <div>
        <MultiBackground className="hero-graph">
          <ParallaxTitle title={legalData.title} intro={legalData.intro} />
          <BouncingMouse />
        </MultiBackground>

        <div className="about-bg">
          <div className="mx-auto max-w-6xl grid lg:grid-cols-2">
            <LegalSection
              id="impressum"
              className="about-text flex flex-col items-stretch"
              heading={impressumData.frontmatter.title}
              markdown={impressumData.html}
            />
            <LegalSection
              id="datenschutz"
              className="about-text flex flex-col items-stretch"
              heading={datenschutzData.frontmatter.title}
              markdown={datenschutzData.html}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

LegalPage.propTypes = {
  data: PropTypes.object.isRequired
};

export const data = graphql`
  query {
    legal: allFile(
      filter: { sourceInstanceName: { eq: "content" }, name: { eq: "legal" } }
    ) {
      ...markdownBasic
    }
    impressum: allFile(
      filter: {
        sourceInstanceName: { eq: "content" }
        name: { eq: "impressum" }
      }
    ) {
      ...markdownBasic
    }
    datenschutz: allFile(
      filter: {
        sourceInstanceName: { eq: "content" }
        name: { eq: "datenschutz" }
      }
    ) {
      ...markdownBasic
    }
  }
`;

export default LegalPage;
