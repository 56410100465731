import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

function LegalSection({ id, className, heading, markdown }) {
  return (
    <section id={id} className={className}>
      <h2>{heading}</h2>
      <div
        className="prose prose-sm lg:prose"
        dangerouslySetInnerHTML={{ __html: markdown }}
      ></div>
    </section>
  );
}

LegalSection.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  markdown: PropTypes.string.isRequired
};

export const query = graphql`
  fragment markdownBasic on FileConnection {
    edges {
      node {
        childMarkdownRemark {
          html
          frontmatter {
            title
            intro
          }
        }
      }
    }
  }
`;

export default LegalSection;
